import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Divider,
  // Customizable Area Start
  Checkbox,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { 
  arrowback, download,
  loader, arrowdown, student, dollarBlueIcon, searchIcon, hamburgerIcon 
} from './assets';
import { DateRange } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import KeyBoardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyBoardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { SideBarNavigation } from "../../../components/src/SideNavbar.web";
import AllPaymentModal from '../../../components/src/AllPaymentModal.web'; 
import RefundModal from "../../../components/src/RefundModal.web";
import InstallmentsModal from "../../../components/src/InstallmentsModal.web";
import ProfileModal from "../../../components/src/ProfileModal.web";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import StatusNotifyModal from "../../../components/src/StatusNotifyModal.web";
import PaymentResponseModal from "../../../components/src/PaymentResponseModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  }
});

const PayOrRefundCardDiv = styled(Box) ({
  "&:hover": {
    backgroundColor: "#FBEAE8 !important",
    
      "& .payOrRefundCardTitleText": {
        color: "#DA291C !important",
      },
      "& .payOrRefundCardTypeWrapper":{
        backgroundColor: "#DA291C !important"
      },
      "& .payOrRefundCardTypeText": {
        color: "white !important"
      },
      "& .payOrRefundCardAmount":{
        color: "#DA291C !important"
      },
  },
  "@media(max-width: 767px)":{
    "& .payOrRefundCardTypeWrapper":{
      width: "fit-content",
      margin: "0 auto",
      marginBottom: '10px',
    },
  }
  
})

const CustomDateRange = styled(DateRange) ({
  
  '& .rdrMonthAndYearWrapper': {
    color: 'white',
    fontSize: '14px',
  },

  '& .rdrMonthName': {
    display: 'none !important',
  },
  
 '& .rdrDayStartPreview': {
    color: '#da291c !important',
  },
  
 '& .rdrDateDisplayWrapper': {
    display: 'none !important'
  },

  '& .rdrDayEndPreview': {
    color: '#da291c !important',
  },

  '& .rdrDayInTheRange': {
    backgroundolor: '#005588 !important',
  },

  '& .rdrDaySelected': {
    backgroundolor: '#003366 !important '
  },

  '& .rdrWeekDay': {
    color: "#da291c !important"
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
    color: "#da291c !important"
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span': {
    color: "#da291c !important"
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrInRange': {
    border: '1px #da291c solid !important', 
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderRadius: 'initial !important,'
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrEndEdge': {
    border: '1px #da291c solid !important', 
    borderLeft: 'none !important',
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrStartEdge': {
    border: '1px #da291c solid !important', 
    borderRight: 'none !important',
  },

  '& .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span': {
    color: "#da291c !important", 
  },

  '& .rdrDayNumber span': {
    fontSize: '12px',
  }
})

const PayOrRefundWrapper = styled(Box)({
    width: '47%',
      "@media(max-width: 991px)": {
        width: '100%'
  },
  "& .payRefundCardTitle": {
    "@media(max-width: 500px)": {
    flexDirection: 'column'
    },
    "& .payOrRefundCardTitleText":{
      "@media(max-width: 500px)": {
      marginBottom: '20px'
        },
    },
    "& .payOrRefundCardAmount": {
      "@media(max-width: 500px)": {
        marginTop: '20px'
          },
    }
  }
}) 

const PageStyle = styled(Box)({
  position: 'absolute',
  right: '20px',
  "@media(max-width: 500px)": {
    position:'static'
      },
})
 
const ChildTabWrapper = styled(Box)({
  width: '100%',
  "& .childData": {
    "@media(max-width: 1200px)": {

    maxWidth: '60%',
    },
    "@media(max-width: 991px)": {
      minWidth: '100%'
},
  },
  "& .childDataWrapper": {
    "@media(max-width: 991px)": {
      flexWrap: 'wrap'
}
  }
})

const MainContainer = styled(Box)({
  width: '100%',
  "& .container": {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    "@media(max-width: 992px)": {
      position: 'unset'
    }
  },
  "& .payment-header": {
    flexWrap: 'wrap',
    position: 'relative',
    "@media(min-width: 768px)": {
      '& .payment-header-icon': {
        display: 'none',
      }
    },
    "@media(max-width: 767px)": {
      gap: '10px',
      '& .payment-header-icon': {
        display: 'flex',
        position: 'absolute',
        left: '13px',
        top: '33px',
      }
    },
    "@media(max-width: 500px)": {
      padding: '24px 40px 24px 24px',
      flexDirection: 'column'
    }
  },
  "& .sidebarStyle":{
    "@media(max-width: 767px)": {
      display: 'none',
    }
  },
    navbarMobile: {
    "@media(max-width: 767px)": {
      display: 'flex',
    }
  },
  "& .paymentHeadingWrapper":{
    "@media(max-width: 767px)": {
      width: 'fit-content'
    }
  },
  "& .profileSection":{
    "@media(max-width: 767px)": {
      width: 'fit-content'
    }
  }
})

const TransactionTabs = styled(Box)({ 
  "@media(max-width: 1024px)": {
    display: 'flex !important',
    flexWrap: 'wrap',
    gap: '10px',
    '& div': {
      width: 'auto !important',
      marginTop:'10px !important',
      marginBottom: '10px !important'
    },
  },

})

// Customizable Area End

import CfnetsuiteintegrationpaymentstatusupdateController, {
  Props,
  configJSON,
} from "./CfnetsuiteintegrationpaymentstatusupdateController";


export default class Cfnetsuiteintegrationpaymentstatusupdate extends CfnetsuiteintegrationpaymentstatusupdateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  alignSoaHeaderRow = (index:number) => {
    switch(index) {
      case 0:
        return "left";
      case 1:
        return "center";
      case 2:
        return "right";
      default:
        return "left";
    }
  }

  dropDownButton = (rowLabel: string) => {
    let isShow = false

    if (rowLabel === "Transaction ID" || rowLabel === "Transaction Date" || rowLabel === "Status") {
      isShow = true
    } 

    return isShow
  }

  setTableRowAlignment = (soaStatus: boolean, soaIndex: number, headerText: string) => {
    if(soaStatus) {
      return this.alignSoaHeaderRow(soaIndex)
    } 
    if(headerText === "Amount") {
      return "right";
    }
    else {
      return "left"
    }
  }

  setTableRowPaddingRight = (soaStatus: boolean, rowLabel: string) => {
    if(soaStatus && rowLabel === "Download") {
      return "35px"
    } 
    if(rowLabel === "Amount") {
      return "15px";
    }
    else {
      return "0px"
    }
  }

  renderDropDownFilter = () => {
    return (
      <>
        {this.state.filterDropDownOpen === "Transactionid" &&
          <Box 
            style={{ 
              width: '165px', 
              height: '264px', 
              border: '1px solid #ecebeb', 
              zIndex: 1000, 
              top: '110%', 
              left: '5%', 
              position: 'absolute',
              backgroundColor: 'white',
              borderRadius: '8px',
            }}
          >

            <Input
              data-test-id="transaction-input-id"
              startAdornment={
                <InputAdornment position='start'>
                  <img 
                    src={searchIcon} 
                    style={{ marginLeft: '5px', height: '20px'}}
                  /> 
                </InputAdornment>
              }
              placeholder="Search"
              style={webStyle.dropDownSearchBox}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTransactionIdSearch(event)}
            />
            <div
              style={webStyle.dropDownContentWrapper}
              data-test-id="filterDropDownId"
              onClick={()=>this.filterDropDownButton(this.state.filterDropDownOpen)}
            >
              {this.state.allTransactionIdData.map((item) => (
                <Typography 
                  data-test-id={`transactionIdData-${item}`}
                  style={
                    this.state.currentFilters.documentNumber  === item ?
                    webStyle.selectedDropDownContent :
                    webStyle.dropDownContent
                  }
                  onClick={() => this.handleTransactionIdFilter(item)}
                >{item}</Typography>
              ))}
            </div>
          </Box>
        }
        {this.state.filterDropDownOpen === "Status" &&
          <Box style={{ 
              width: '165px', 
              height: '264px', 
              border: '1px solid #ecebeb', 
              zIndex: 1000, 
              top: '110%', 
              left: '5%', 
              position: 'absolute',
              backgroundColor: 'white',
              borderRadius: '8px',
              overflowY: "auto"
            }}>
            <div
              style={webStyle.statusDropDownContentWrapper}
            >
              {this.state.actionsFilter.map((item) => (
                <Typography 
                data-test-id={`statusDataId-${item.name}`}
                  style={
                    this.state.currentFilters.status === item.name ?
                      webStyle.selectedDropDownContent :
                        webStyle.dropDownContent
                  }
                  onClick={() => this.handleStatusFilter(item.name)}
                >{item.name === 'in_progress' ? 'In Progress' : item.name}</Typography>
              ))}
            </div>
          </Box>
        }
        {this.state.filterDropDownOpen === "Date" &&
          <Box style={{ 
              width: 'max-content', 
              height: 'max-content', 
              zIndex: 1000, 
              top: '110%', 
              left: '5%', 
              position: 'absolute',
              backgroundColor: 'white',
              border: "1px solid #ecebeb",
              borderRadius: "8px",
              padding: "0px 5px"
            }}
          >
            <CustomDateRange
              data-test-id="dateId"
              locale={enUS}
              months={1}
              moveRangeOnFirstSelection={false}
              direction='horizontal'
              rangeColors={["#fbeae8"]}
              ranges={this.state.calendarRanges}
              onChange={(item: any) => this.handleDateRangeOnChange(item.selection)}
            />
          </Box>
        }
      </>
    )
  }

  tableFunction = (row: any, soaStatus: boolean, index: number) => {
    const headerAlign = this.setTableRowAlignment(soaStatus, index, row.label)
    return (
      <TableCell
        key={row.id}
        align={headerAlign}
        style={{ 
          fontSize: "16px", flexDirection: "row", fontWeight: "bold", 
          width: row.label === "Action" ? "14%": "auto",
          paddingRight: this.setTableRowPaddingRight(soaStatus, row.label) ,
          position: "relative",
        }}
        padding={'default'}
        sortDirection={this.state.orderBy === row.id ? this.state.order : false}
      >
          {
            this.dropDownButton(row.label) ?
            <div 
              id="rowHeadingId" 
              data-test-id={`dropdown-id-${row.label}`}
              style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: row.label === "Amount" ? "flex-end": "flex-start",
                cursor: "pointer"
              }}
              onClick={() => this.filterDropDownButton(row.id)}
            >
              {row.label}
              <img src={arrowdown} style={{ marginLeft: '5px'}}/> 
            </div>
            :
            <>{row.label}</>
          }

        {row.id === this.state.filterDropDownOpen && this.state.isFilterOpen && 
          <>
            {this.renderDropDownFilter()}
          </>
        }
        
      </TableCell>
    )
  } 

  renderPayOrRefundCard = (cardTitleText: string, btnTextAndType: string, cardAmount: string) => {
    return (
      <PayOrRefundWrapper>
      <PayOrRefundCardDiv id="payOrRefundCardWrapperId" className="PayOrRefundCardDiv" style={webStyle.payOrRefundCardWrapper}>
        <div className="payRefundCardTitle" style={{ display: 'flex', justifyContent: "space-between", marginLeft: '10px', marginRight: '10px'}}>
            <div className={"payOrRefundCardTitleText"} style={webStyle.parOrRefundCardTitleStyle}>
                {cardTitleText}
            </div>
            <div className={"payOrRefundCardTypeWrapper"} style={{ borderBottom: "1px solid white", borderRadius: '8px', backgroundColor: "white" }}>
                <div 
                  className={"payOrRefundCardTypeText"}
                  data-test-id={`payOrRefundCardTypeText-${btnTextAndType}`} 
                  style={webStyle.payOrRefundCardBtnStyle}
                  onClick={() => this.payStatus(btnTextAndType.toLowerCase(), cardAmount)} 
                >
                  {btnTextAndType}
                </div>
            </div>
        </div>

        <div className={"payOrRefundCardAmount"} style={webStyle.payOrRefundCardAmntStyle}>
          S$ {cardAmount}
        </div>
      </PayOrRefundCardDiv>
      </PayOrRefundWrapper>
    )
  }

  renderSelectedPayOrRefundBox = ( amount: string | number, buttonText: string, handleModalOpen: () => void) => {
    return (
      <Box sx={webStyle.secondContent1}>
        <div style={webStyle.headerPay}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={webStyle.imgBoxStyle}>
              <img src={dollarBlueIcon} style={webStyle.dollarImgStyle} ></img>
            </div>
            <div style={{ marginLeft: '10px', display:'flex', flexDirection: 'column' }}>
              <div style={{ color: '#7d7a78', fontSize: '14px', fontFamily: 'Inter' }}>Selected Amount</div>
              <div style={{ color: '#3D3935', fontSize: '30px', fontFamily: 'Inter', fontWeight: 700 }}>S$ {amount}</div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ borderBottom: "1px solid white", borderRadius: '8px', backgroundColor: "#0032e1", marginTop: '10px', cursor:'pointer' }}>
              <div data-test-id={buttonText} style={webStyle.buttonPayOrRefundTextStyle} onClick={handleModalOpen}>
                {buttonText}
              </div>
            </div>
          </div>
        </div>
      </Box>
    )
  }

  renderSelectedPayOrRefundOpen = () => {
    return (
      <>
        {/* pay block */}
        {this.state.pay === true &&
          this.renderSelectedPayOrRefundBox(Number(this.state?.subtotal)?.toFixed(2) , "Pay", this.handlePaymentModalOpen)
        }
        {/* refund block */}
        {this.state.refund === true &&
          this.renderSelectedPayOrRefundBox(Number(this.state?.refundSubtotal)?.toFixed(2), "Refund", this.handleRefundPaymentModalOpen)
        }
      </>
    )
  }

  renderPaymentTabs = (paymentTabKey: string, tabText: string, tabSelected: boolean, isDisplay: string) => {
    return (
      <div 
        data-test-id={paymentTabKey.toLowerCase()} 
        style={{ 
          cursor: "pointer", textAlign: "center", marginTop: '25px', 
          marginBottom: '25px', width: '100%', color: tabSelected ? "red" : '#7D7A78', 
          fontSize: 16, fontFamily: 'Inter', fontWeight: 700, display: isDisplay
        }} 
        onClick={() => {
          this.handleSetInitialFilters()
          this.changePayment(paymentTabKey)
        }}
      >
          {tabText}
      </div>
    )
  }

  handleStatusTextColor = (statusVal: string) => {
    switch(statusVal?.toUpperCase()) {
      case "PAID":
        return "#059669";
      case "PENDING":
        return "#d97706";
      case "OVERDUE":
        return "#0032e1";
      case "IN PROGRESS":
        return "#F76707";
      case "REFUNDED":
        return "#8833FF";
      case "PAY": 
        return "white";
      case "REFUND": 
        return "#3d3935";
      default:
        return "white";
    }
  }

  handleStatusBackgroundColor = (statusVal: string) => {
    switch(statusVal?.toUpperCase()) {
      case "PAID":
        return "#d1fae5";
      case "PENDING":
        return "#fef3c7";
      case "OVERDUE":
        return "#d4f5ff";
      case "IN PROGRESS":
        return "#FFF5EF";
      case "REFUNDED":
          return "#F6F0FF";
      case "PAY": 
        return "#059669"; 
      case "REFUND": 
        return "white"; 
      default:
        return "white";
    }
  }

  renderTableAction = (action: string, index:number, transactionid:string, amount:string, installment:boolean, isInvoiceStatus:boolean) => {
    
    return (
      <div 
        data-test-id={`installment-modal-id-${index}`}
        style={{ 
          borderRadius: '5px', 
          color: this.handleStatusTextColor(action),
          backgroundColor: this.handleStatusBackgroundColor(action), 
          fontSize: 16, 
          fontWeight: action === "Pay" ? "bold" : "normal",
          padding: "4px 30px",
          border: action === "Refund" ? "1px solid #C3C2C0": "none",
          width: "100%",
          marginRight: "5px",
          cursor: "pointer"
        }}
        onClick={() => {
          if (installment === true && isInvoiceStatus) {
            this.handleInstallmentsModalOpen(transactionid, amount);
          } else if (this.state.invoiceStatus) {
            this.handleAllPaymentModalOpen(transactionid, amount);
          } else if (this.state.creditStatus) {
            this.handleRefundModalOpen(transactionid, amount);
          }
        }}
      >
        {action}
      </div>
    )
  }

  renderNestedTable = (transactionData:any, installment:boolean, installmentData:any) => {
    return (
      <>
        <Box id="nestedTable2" style={{ width: "68%", margin: "auto" }}>
          <Typography>Details</Typography>
        </Box>
        <Divider style={{ margin: '5px 0px' }}/>
      <Box id="nestedTable2" style={webStyle.nestedTableBoxWrapper}>
        <Table style={{ minWidth: "700px" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '100px' }}>Amount</TableCell>
              <TableCell style={{ width: '100px' }}>Payment Date</TableCell>
              <TableCell style={{ width: '100px' }}>Payment Mode</TableCell>
              <TableCell style={{ width: '100px' }}>Reference Text</TableCell>
              <TableCell style={{ width: '100px' }}>Receipt ID</TableCell>
            </TableRow>
          </TableHead>
          {installment ? (installmentData.data.length > 0 && installmentData.data.map((item:any)=>(
            <TableBody>
            <TableRow>
              <TableCell style={{ width: '100px' }}>S${Number(item.attributes.amount).toFixed(2)}</TableCell>
              <TableCell style={{ width: '100px' }}>{moment(item.attributes.payment_date).format('DD-MMM-YYYY')}</TableCell>
              <TableCell style={{ width: '100px' }}>{item.attributes.payment_mode}</TableCell>
              <TableCell style={{ width: '100px' }}>{item.attributes.reference_text}</TableCell> 
              <TableCell style={{ width: '100px', color: '#da291c' }}>
              {item.attributes.receipt && item.attributes.receipt.url &&
              <div style={{display:'flex', alignItems:'center'}}>
                <img 
                  src={download} alt="download"
                  data-test-id={`receipt-${item.attributes.receipt_id}`} 
                  style={{marginRight: "5px", marginTop: "-5px", cursor: "pointer"}}
                  onClick={()=>this.handleDownloadClick(item.attributes.receipt.url, 
                    this.state.selectionMode, item.attributes.receipt_id
                    )}
                    />
                <span>{item.attributes.receipt_id}</span>
                </div>
              }
              </TableCell>
            </TableRow>
            </TableBody>
          ))
          ) : (
            <TableBody>
              {transactionData.data.length > 0 && transactionData.data.map((item:any)=>(           
            <TableRow>
              <TableCell style={{ width: '100px' }}>S${Number(item.attributes.charge_amount).toFixed(2)}</TableCell>
              <TableCell style={{ width: '100px' }}>{moment(item.attributes.charged_at).format('DD-MMM-YYYY')}</TableCell>
              <TableCell style={{ width: '100px' }}>{item.attributes.payment_mode}</TableCell>
              <TableCell style={{ width: '100px' }}>{item.attributes.charge_reference}</TableCell> 
              <TableCell style={{ width: '100px', color: '#da291c' }}>
              {item.attributes.receipt &&
              <div style={{display:'flex', alignItems:'center'}}>
                <img 
                  src={download} alt="download"
                  data-test-id={`receipt-${item.attributes.receipt_number}`} 
                  style={{marginRight: "5px", marginTop: "-5px", cursor: "pointer"}}
                  onClick={()=>this.handleDownloadClick(item.attributes.receipt, 
                    this.state.selectionMode, item.attributes.receipt_number
                    )}
                    />
                  <span>{item.attributes.receipt_number}</span>
              </div>
              }
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
          )}
        </Table>

      </Box>
      </>
    )
  }

  isDropDownEnabled = (attributes:any) => {
    const hasTransactionData = () => {
      return attributes.transaction_data &&
             Object.keys(attributes.transaction_data.data || {}).length > 0;
    };
  
    const hasInstallmentData = () => {
      return attributes.installment_list &&
             Array.isArray(attributes.installment_list.data) &&
             attributes.installment_list.data.length > 0;
    };
  
    if (attributes.installment === false) {
      return hasTransactionData();
    } else if (attributes.installment === true) {
      return hasInstallmentData();
    }
  
    return false;
  }
  
  renderActionButton = ( index:number, attributes:any, n:any, isInvoiceStatus: boolean) => {
    const isDropDown = isInvoiceStatus && this.isDropDownEnabled(attributes);
    const amount = attributes && String(attributes.status).toUpperCase() === "PAID" || attributes && String(attributes.status).toUpperCase() === "REFUNDED"
    ? `${attributes && Number(attributes.amount).toFixed(2)}` 
    : `${attributes && Number(attributes.remaining_amount).toFixed(2)}`
    
    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        {n && n.Action !== "" &&
          <Typography
            style={{ marginRight: '5px', width: isDropDown ? "60%": "85%", textAlign: 'center' }}
          >
            {this.renderTableAction(n.Action, index, attributes && attributes.ns_internal_id, amount, attributes && attributes.installment, isInvoiceStatus)} 
          </Typography>
        }
        {isDropDown && attributes && attributes.ns_internal_id &&
          <img
            data-test-id= {`actionButtonDropdownId-${index}`} 
            src={arrowdown} 
            style={{ padding: '5px', backgroundColor: '#ecebeb', borderRadius: '5px', cursor: 'pointer' }}
            onClick={() => this.handleNestedTableOpen(n.attributes.ns_internal_id)}
          />
        }
      </div> 
    )
  }

  renderSoaData = (attributes:any) => {
    return (
      <>
        <TableCell align="left">{attributes.child && attributes.child.attributes && attributes.child.attributes.child_firstname}</TableCell>
        <TableCell align="center">{attributes.soa_month}</TableCell>
        <TableCell align="right" style={{ paddingRight: "90px", width: "40%" }}>
        {attributes.soa_pdf && attributes.soa_pdf.url && 
          <img data-test-id="soaDownloadId" src={download} style={{cursor: "pointer"}}
          onClick={()=>this.handleDownloadClick(attributes.soa_pdf.url, this.state.selectionMode, attributes.child_cis_id)} 
          />
        }
        </TableCell>
      </> 
    )
  }

  renderTableData = (attributes:any, n:any, index:number) => {
    return (
      <>
        <TableCell align="left" component="th" scope="row" padding="default">
          {n.numIndex}
        </TableCell>
        <TableCell align="left">
          <Box display={"flex"} alignItems={"center"}>
            <Typography style={{ fontSize: "14px" }}>{attributes.document_number}</Typography>
            {
              this.state.invoiceStatus && attributes.installment === true && 
              <Typography
                style={{ 
                  backgroundColor: "#D4F5FF", padding: "6px 15px", color: "#0032E1", 
                  fontSize: "12px", borderRadius: "4px", marginLeft: "10px"
                }}
                
              >
                Installment
              </Typography>
            }
          </Box>
        </TableCell>
        <TableCell align="left">{attributes.memo}</TableCell>
        {!this.state.creditStatus && 
          <TableCell align="left">{moment.parseZone(attributes.date).format('DD-MMM-YYYY')}</TableCell>
        }
        {this.state.invoiceStatus && 
          <TableCell align="left">{attributes.due_date ? moment.parseZone(attributes.due_date).format('DD-MMM-YYYY'): "--"}</TableCell>
        }
        { !this.state.cardStatus &&
          <TableCell align="center" style={{ width: '15%' }}>
            <div 
              style={{ 
                borderRadius: '5px', 
                color: this.handleStatusTextColor(String(attributes?.status).replace(/_/g, ' ')), 
                backgroundColor: this.handleStatusBackgroundColor(String(attributes?.status).replace(/_/g, ' ')), 
                fontSize: "14px", 
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "85%",
                height: "30px"
              }}
            >
             {attributes.status && String(attributes.status).replace(/_/g, ' ').toUpperCase()}
            </div>
          </TableCell> 
        }
        <TableCell align="left">
        {attributes?.invoice?.url && 
          <img
            style={{cursor: "pointer"}} 
            data-test-id={`pdfDownloadId-${attributes.ns_internal_id}`} 
            src={download} 
            onClick={()=> 
              this.handleDownloadClick(attributes.invoice.url, this.state.selectionMode, attributes.document_number)
            } 
            />
        }
        </TableCell>
        <TableCell align="right">{attributes && String(attributes.status).toUpperCase() === "PAID" || attributes && String(attributes.status).toUpperCase() === "REFUNDED" || this.state.cardStatus === true
          ? `S$${attributes && Number(attributes.amount).toFixed(2)}` 
          : `S$${attributes && Number(attributes.remaining_amount).toFixed(2)}`}
        </TableCell>
        {!this.state.cardStatus && 
          <TableCell align="center" style={{ textAlign: 'left' }}>
          {this.renderActionButton(index, attributes, n , this.state.invoiceStatus)}
        </TableCell>
        }
      </>
    )
  }
  
  renderTableBody = () => {
    return (<>
    {this.state.orderTransactionData.length > 0 && this.state.orderTransactionData.map((n, index) => {
      
      return (
        <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={n.attributes.ns_internal_id}
        >
          { (this.state.invoiceStatus || this.state.creditStatus) &&
            <TableCell padding="checkbox">
              <Checkbox
                data-test-id={`checkboxId-${index}`}
                checked={n.isSelected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectRowItem(event, n.attributes.ns_internal_id)}
              />
            </TableCell>
          }

          {
            this.state.soaStatus === true ? 
            this.renderSoaData(n?.attributes)
            : this.renderTableData(n?.attributes, n, index)
          }
         </TableRow>
         {this.state.expandedRow === n.attributes.ns_internal_id && (

                <TableRow style={{ backgroundColor: '#fafafa' }}>
                  <TableCell colSpan={10}>
                    {this.renderNestedTable(n.attributes.transaction_data, n.attributes.installment, n.attributes.installment_list)}
                  </TableCell>
                </TableRow>
            )
            }
            </>
          );
        }
    )}
    </>)
  }

  renderPaymentListTabName = (item: any, tabSelected: string) => {
    let isSelected;
    
    if (item === "All") {
      isSelected = tabSelected === item;
    } else {
      isSelected = tabSelected === item.child_cis_id;
    }
    return(
      <div 
        key={`${item.child_cis_id}-${tabSelected}`}
        data-test-id={`childCisId-${item.child_cis_id}`}
        onClick={() => item === "All" ? this.toggle("All") : this.toggle(item.child_cis_id)} 
        style={{ 
            border: isSelected ? "1px solid #ee9d97" : 0, 
            borderRadius: isSelected ? '50px' : 0, 
            backgroundColor: isSelected ? "#fbeae8" : "", 
            margin: '12px 10px', 
            padding: '0px 10px',
            cursor: "pointer",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
      >
          <div 
            data-test-id={item.child_cis_id}
            style={{ 
              textAlign: "center", 
              margin: '10px', 
              color: isSelected ? 'crimson' : "#7D7A78",
              fontSize: 16, 
              fontFamily: 'Inter', 
              fontWeight: 700 
            }}
          >
             {item === "All" ? "All" : item.child_firstname}
          </div>
      </div>
    )
  }
  
  renderUserModalOption = () => {
    return (
      <ProfileModal
        isProfileModalOpen={this.state.isUserOptionModalOpen}
        handleUserModalOptionOpen={this.handleUserOtherOption}
        handleNavigationPath={(path:string) => this.handlePathNavigation(path)}
      />
    )
  }
  
  getTextDecoration=(page:number, currentPage:number,showPageNum:boolean)=>{
    if(page===currentPage || !showPageNum){
      return "none"
    }
    return "underline" 
  }
  
  renderTablePagination = () => {
    const { page, pageNumbers } = this.state;
    return (
      <div style={webStyle.tablePaginationWrapper}>
        <div style={webStyle.paginationInnerCommonWrapper}>
          {this.renderPaginationButton('previous')}
          {this.renderPageNumbers()}
          {this.renderPaginationButton('next')}
        </div>
        <PageStyle>
          <Typography>{`${page}-${pageNumbers} of ${pageNumbers} pages`}</Typography>
        </PageStyle>
      </div>
    );
  }
  
  renderPaginationButton = (type:any) => {
    const { page, pageNumbers } = this.state;
    const isPrevious = type === 'previous';
    const isNext = type === 'next';
    const onClick = isPrevious ? 
      () => page > 1 && this.handlePageChange(page - 1) :
      () => page < pageNumbers && this.handlePageChange(page + 1);
    
    const Icon = isPrevious ? KeyBoardArrowLeftIcon : KeyBoardArrowRightIcon;
    const cursor = (isPrevious && page > 1) || (isNext && page < pageNumbers) ? "pointer" : "default";
    const opacity = (isPrevious && page > 1) || (isNext && page < pageNumbers) ? 1 : 0.5;
  
    return (
      <Icon 
        data-test-id={isPrevious ? "previousPageID" : "nextPageID"}
        style={{ padding: '5px', cursor, opacity }} 
        onClick={onClick}
      />
    );
  }
  
  renderPageNumbers = () => {
    const { page, pageNumbers } = this.state;
    return Array(pageNumbers).fill(0).map((_, index) => {
      const currentPage = index + 1;
      const { showPageNum, showDots } = this.getPageDisplayInfo(currentPage);
  
      if (!showPageNum && !showDots) return null;
  
      return (
        <Typography 
          key={currentPage} 
          data-test-id={`selectedPageID-${index}`}
          onClick={() => showPageNum && this.handlePageChange(currentPage)} 
          style={{ 
            padding: '5px', 
            color: page === currentPage ? "red" : "#7D7A78", 
            textDecoration: this.getTextDecoration(page, currentPage, showPageNum),
            cursor: showPageNum ? "pointer" : "default"
          }}
        >
          {showPageNum ? currentPage : "..."}
        </Typography>
      );
    });
  }
  
  getPageDisplayInfo = (currentPage:any) => {
    const { page, pageNumbers } = this.state;
    let showPageNum = false;
    let showDots = false;
  
    if (currentPage === 1 || currentPage === pageNumbers) {
      showPageNum = true;
    } else if (currentPage === page || currentPage === page + 1 || currentPage === page - 1) {
      showPageNum = true;
    } else if (page <= 3 && currentPage <= 3) {
      showPageNum = true;
    } else if (page >= pageNumbers - 2 && currentPage >= pageNumbers - 2) {
      showPageNum = true;
    } else if (currentPage === 2 || currentPage === pageNumbers - 1) {
      showDots = true;
    }
  
    return { showPageNum, showDots };
  }

  // Customizable Area End

  render() {
    const {pending_amount, refunded_amount, last_transaction_updated } = this.state.pageData
    const {lastSyncMessage} = this.state
    
    return (
      // Customizable Area Start
      <>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <MainContainer>
            <Box className="container" sx={webStyle.container} style={{ minHeight: '100vh' }}>
            {this.state.sideBarOpen && <SideBarNavigation className="sideBarStyle" handleNav={this.handlePathNavigation} />} 
              <Box sx={webStyle.content}>
                <div style={webStyle.header} className="payment-header">
                  <div className="payment-header-icon">
                    <img data-test-id="sideBarToogleId" onClick={()=>this.handleSideBar()} src={hamburgerIcon} alt="hamburgerIcon" style={{ width: "20px", height: "20px", cursor: "pointer"}}/>
                  </div>
                  <div className="paymentHeadingWrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img data-test-id="backArrowImgId" onClick={() => {}} style={{ width: '24px', height: '24px' }} src={arrowback} />
                    <div style={{ marginLeft: '5px', color: '#3D3935', fontSize: 24, fontFamily: 'Inter', fontWeight: 700 }}>{configJSON.paymentText}</div>
                  </div>
                  <div className="profileSection" data-test-id="profileModalId" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer', position: "relative" }} onClick={() => this.handleUserOtherOption()}>
                    <img 
                      src={this.state.headerUserProfilePic? this.state.headerUserProfilePic: student} 
                      style={{ width: '40px', height: '40px', borderRadius: '50%', border: '3px #ECEBEB solid' }} 
                    />
                    <div 
                      style={{ 
                        marginLeft: '5px', color: '#3D3935', fontSize: 14, fontFamily: 'Inter', 
                        fontWeight: 700, margin: "0px 8px", maxWidth: "150px", textOverflow: "ellipsis", 
                        whiteSpace: "nowrap", overflow: "hidden"
                      }}
                    >
                      Hi, {this.state.userName}
                    </div>
                    <img style={{ width: '20px', height: '20px' }} src={arrowdown} />
                  </div>
                </div>

                { this.renderUserModalOption() }
                {this.state.showLoader && <Loader loading={true}/>}
                <ChildTabWrapper>
                <Box sx={webStyle.firstContent}>
                  <div style={webStyle.body1}>
                    <div className="childDataWrapper" style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginLeft: '10px', marginRight: '10px', padding: "5px 5px" }}>
                      <div data-test-id="childDataIcons" className="childData" style={{width:'60%', alignItems: "center", display: "grid"}}> 
                        <div className="scrollBox" style={{ display: 'flex', flex: 1, overflowX: "scroll"  }}>
                          {this.renderPaymentListTabName("All", this.state.selectedToggleTab)}
                          {
                            this.state.childDetails.map((item: any) => (
                              this.renderPaymentListTabName(item.attributes, this.state.selectedToggleTab)
                              ))
                            }
                        </div>
                      </div>
                      <div style={{display: 'none'}}>
                      <div style={{ display: 'flex', alignItems: "flex-end", justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: "center", fontSize: '10px', lineHeight: '18px', color: '#7d7a78' }}>Transactions are Updated</div>
                        <div style={{ display: 'flex', alignItems: "center", color: '#7d7a78' }}>{last_transaction_updated}</div>
                      </div>
                      <div style={{ justifyContent: "flex-end" }}>
                        <Box
                          data-test-id="syncButtonId" 
                          onClick={this.handleSyncClick} 
                          style={{ 
                            border: "1px solid white", 
                            borderRadius: '10px 10px 10px 10px', 
                            backgroundColor: "#da291c", 
                            margin: '10px', 
                            cursor: "pointer" 
                          }}>
                          <div 
                            style={{ 
                              display:"flex",
                              alignItems: "center",
                              margin: '10px', 
                              color: "white", 
                              fontSize: 17, 
                              fontFamily: 'Inter', 
                              fontWeight: 700,
                            }}
                          >
                            <span>
                              Sync
                            </span>
                            <img
                              style={{
                                width: '24px',
                                height: '24px',
                                marginLeft: "10px",
                                animation: this.state.isSyncButtonRotating ? 'spin 2s linear infinite' : 'none',
                              }}
                              src={loader}
                              alt="Loader"
                            />
                          </div>
                        </Box>
                      </div>

                      </div>
                    </div>
                  </div>
                </Box>
                </ChildTabWrapper>
                <Box sx={webStyle.secondContent}>

                  <div 
                    data-test-id="payAndRefundWrapperId" 
                    style={{ display: "flex", justifyContent:"space-between", alignItems:'center', padding: "0px 25px",flexWrap: 'wrap'  }}
                  >
                    {this.renderPayOrRefundCard("Total Pending Amount", "Pay", Number(pending_amount).toFixed(2))}
                    {this.renderPayOrRefundCard("Refundable Amount", "Refund", Number(refunded_amount).toFixed(2))}
                  </div>
                </Box>

              {this.renderSelectedPayOrRefundOpen()}
              <AllPaymentModal 
                isAllPaymentModalOpen={this.state.isAllPaymentModalOpen} 
                selectedData={this.state.selectedRows} 
                subtotal={this.state.subtotal}
                handleAllPaymentModalClose={this.handleAllPaymentModalClose}
                handlePaymentModalNext={this.handlePaymentModalNext}
                showAll={this.state.showAllTransactions}
                handleShowAllTransactions={this.handleShowTransactions}
                totalAmount={this.state.totalAmount}
                pendingModalOpen={this.state.pendingModalOpen}
                totalPendingTransactions={this.state.totalPendingTransactions}
              />
              <RefundModal 
                isRefundModalOpen={this.state.isRefundModal}
                handleRefundModalClose={this.handleRefundModalClose} 
                handleRefundPaymentMethod={this.handleRefundPaymentMethod} 
                refundPayment={this.state.refundPaymentMethod}
                payNowId={this.state.payNowId}
                payNowName={this.state.payNowName}
                contactNumber={this.state.contactNumber}
                payNowIdError={this.state.payNowIdError}
                payNowNameError={this.state.payNowNameError}
                contactNumberError={this.state.contactNumberError}
                setPayNowId={this.setPayNowId}
                setPayNowName={this.setPayNowName}
                setContactNumber={this.setContactNumber}
                handlePayNowSubmit={this.handlePayNowSubmit}
                beneficiarysName={this.state.beneficiarysName}
                beneficiaryNameError={this.state.beneficiarysNameError}
                banksName={this.state.banksName}
                banksNameError={this.state.banksNameError}
                accountNumber={this.state.accountNumber}
                accountNumberError={this.state.accountNumberError}
                swiftCode={this.state.swiftCode}
                swiftCodeError={this.state.swiftCodeError}
                branchCode={this.state.branchCode}
                bankCode={this.state.bankCode}
                setBeneficiarysName={this.setBeneficiarysName}
                setBanksName={this.setBanksName}
                setAccountNumber={this.setAccountNumber}
                setSwiftCode={this.setSwiftCode}
                setBranchCode={this.setBranchCode}
                setBankCode={this.setBankCode}
                handleByBankTransferSubmit={this.handleByBanksTransferSubmit}
                refundSubtotal={this.state.refundSubtotal}
                refundTotalAmount={this.state.refundTotalAmount}
                refundPendingModalOpen={this.state.refundPendingModalOpen}
                bankCodeError={this.state.bankCodeError}
                branchCodeError={this.state.branchCodeError}
              />
              <InstallmentsModal 
                isInstallmentsModalOpen={this.state.isInstallmentModalOpen} 
                payingAmount={this.state.payingAmount} 
                payingAmountError={this.state.payingAmountError} 
                handleInstallmentModalClose={this.handleInstallmentsModalClose} 
                setPayingAmount={this.setPayingAmount} 
                handleSubmit={this.handleInstallmentSubmit}
                installmentAmount={this.state.installmentAmount}
                selectedRows={this.state.selectedRows}
              />
              <StatusNotifyModal 
                isModalOpen={this.state.isProfileUpdateStatusModal}
                modalMessage={this.state.profileUpdateStatus}
                isSessionExpired={this.state.isProfileDetailsFetch}
                handleNavigationPath={(path: string) => this.handleSessionExpireModal(path)}
              />
               <PaymentResponseModal
                isModalOpen={this.state.isPaymentResponseModalOpen}
                modalMessage={this.state.paymentResponseMessage}
                handleModalClose={this.handlePaymentResponseModalClose}
                isPaymentFailed={this.state.isPaymentFailed}
                refundMessage={this.state.refundMessage}
                handlePayAgain={this.handlePayAgain}
              />
                <Box sx={webStyle.mainBox}>
                <TransactionTabs style={webStyle.body}>
                  {this.renderPaymentTabs("Invoice", "Invoice", this.state.invoiceStatus, "")}
                  {this.renderPaymentTabs("Credit", "Credit", this.state.creditStatus, "")}
                  {this.renderPaymentTabs("Cash", "Cash Sales", this.state.cardStatus, "")}
                  {this.renderPaymentTabs("SOA", "Statement of Account", this.state.soaStatus, "")}
                </TransactionTabs>
                {this.state.orderTransactionData.length > 0 ?
                <>  
                <div style={{ width: '100%',overflow: 'auto', minHeight: '300px', }}>
                  <Table style={webStyle.table} aria-labelledby="tableTitle">
                    <TableHead>
                      <TableRow>
                        { (this.state.invoiceStatus || this.state.creditStatus) && 
                          <TableCell padding="checkbox">
                            <Checkbox
                              // indeterminate={numSelected > 0 && numSelected < rowCount}
                              data-test-id="selectAllCheckboxId"
                              checked={this.state.isSelectAll}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectAllCheck(event)}
                            />
                          </TableCell>
                        }
                        {this.state.tableHeaderData.map(
                          (row, index) => (
                            this.tableFunction(row, this.state.soaStatus, index)
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    
                    <TableBody>
                      {this.renderTableBody()}
                    </TableBody>
                  </Table>
                </div>
                {this.renderTablePagination()}
                </>
                :
                <div style={{ 
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  backgroundColor: "#DA291C",
                  color: "white",
                  fontWeight: "bold"
                }}>
                  No data available to show
                </div>
                }
              </Box>
              </Box>
            </Box>
            </MainContainer>
          </Container>

        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
  }

// Customizable Area Start
const webStyle = {
  tableStatus: { border: "1px solid white", borderRadius: '10px 10px 10px 10px', backgroundColor: "red", margin: '10px', cursor: "pointer" },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    '& *': {
      fontFamily: "'Inter', sans-serif",
      boxSizing: 'border-box'
    },
  },
  navbar: {
    width: 120,
    height: '100vh',
    padding: '0px 20px',
    justifyContent: 'center',
    borderRight: '1px #ECEBEB solid',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    padding: '24px 40px 24px 0px',
    paddingLeft: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "aliceblue",
  },
  headerPay: {
    padding: '24px 40px 24px 0px',
    paddingLeft: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "white",
    borderRadius: '5px'
    // flex: '1',
    // overflowY: 'auto',
  },
  body: {
    display: 'grid',
    gridTemplateColumns: 'repeat(30, auto)',
    backgroundColor: "white",
  },
  body1: {
    gridTemplateColumns: 'repeat(30, auto)',
    backgroundColor: "white",
    width: "100%",
    borderRadius: "5px",
  },
  content: {
    flex: '1',
    overflowY: 'auto',
    backgroundColor: "aliceblue",
    padding: "15px",
    height: "100vh",
  },

  mainBox: {
    width: "97%",
    height: "max-content",
    margin: "20px",
    backgroundColor: "white"
  },
  form: {
    padding: '28px 47px',
  },
  firstContent: {
    width: "97%",
    margin: "20px",
    backgroundColor: "white",
    borderRadius: '5px 5px 5px 5px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .scrollBox": {
   
    },
    "& .scrollBox::-webkit-scrollbar": {
      height: "4px",
    },
    "& .scrollBox::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "& .scrollBox::-webkit-scrollbar-thumb": {
      background: "#b6b6b675",
      borderRadius: "10px",
    },
    "& .scrollBox::-webkit-scrollbar-thumb:hover": {
      background: "#000000e6",
    },
  },
  table: {
    minWidth: 1020,
  },
  secondContent: {
    width: "97%",
    margin: "20px",
    backgroundColor: "white",
    borderRadius: '5px 5px 5px 5px'
  },
  secondContent1: {
    width: "97%",
    margin: "20px",
    backgroundColor: "white",
    borderRadius: '5px 5px 5px 5px'
  },
  imgBoxStyle: {
    backgroundColor: '#e2f8ff', 
    padding: '15px', 
    borderRadius: '8px' 
  },
  dollarImgStyle: { 
    width: '24px', 
    height: '24px', 
    borderRadius: '20%' 
  },
  buttonPayOrRefundTextStyle:{
    textAlign: "center" as "center", 
    margin: '8px 10px', 
    color: 'white', 
    fontSize: '14px', 
    fontFamily: 'Inter', 
    fontWeight: 700, 
    width: "100px" 
  },
  payOrRefundCardWrapper: { 
    width: '100%', 
    height: '70%', 
    borderRadius: '8px', 
    backgroundColor: "#da291c",
    margin: '20px 0px', 
    padding: '15px 2px',
  "@media(maxWidth: 768px)":{
      width: '100%',
    }
  },
  parOrRefundCardTitleStyle: { 
    textAlign: "center" as "center", 
    color: 'white', 
    fontSize: 14, 
    fontFamily: 'Inter', 
    fontWeight: 700 
  },
  payOrRefundCardBtnStyle: { 
    cursor: "pointer", 
    textAlign: "center" as "center", 
    margin: '5px', 
    color: '#da291c', 
    fontSize: 16, 
    fontFamily: 'Inter', 
    fontWeight: 700, 
    padding: '4px 10px', 
    width: "120px" 
  },
  payOrRefundCardAmntStyle: { 
    marginLeft: '10px', 
    color: 'white', 
    fontSize: 30, 
    fontFamily: 'Inter', 
    fontWeight: 700 
  },
  nestedTableBoxWrapper:{
    width: "70%",
    display: "flex",
    flexDirection: "column" as "column",
    margin: "auto",
  },
  userOptionModalWrapper:{
    width: "178px",
    height: "100px",
    border: "1px solid #ecebeb",
    padding: "8px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute" as "absolute",
    backgroundColor: "white",
    top: "90px",
    right: "50px",
    zIndex: 999,
  },
  userProfileOptionStyle:{
    backgroundColor: "#FBEAE8",
    width: "162px",
    height: "42px",
    borderRadius: "4px 4px 0px 0px",
    padding: "12px 16px",
    gap: "8px", 
    display: "flex",
    fontSize: "12px",
    color: "#DA291c",
    cursor: "pointer",
  },
  userLogOutOptionStyle:{
    backgroundColor: "white",
    width: "162px",
    height: "42px",
    display: "flex",
    gap: "8px", 
    fontSize: "12px",
    padding: "12px 16px",
    borderRadius: "0px 0px 4px 4px",
    cursor: "pointer",
  },
  actionCellWrapper: { 
    display: 'flex', 
    gap: '10px', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    width: '90%' 
  },
  dropDownContentWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '200px',
    overflow: 'auto',
    margin: '7px'
  },
  statusDropDownContentWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '7px'
  },
  dropDownSearchBox: { 
    padding: '0px 8px',
    border: '1px solid gray',
    margin: '7px',
    borderRadius: '4px',
  },
  dropDownContent: { 
    padding: '12px 16px', 
    width: '100%',
    textAlign: 'left' as 'left',
    cursor: 'pointer',
    fontSize: '14px'
  },
  selectedDropDownContent: { 
    padding: '12px 15px', 
    minWidth: '100%',
    textAlign: 'left' as 'left',
    cursor: 'pointer',
    color: '#da291c',
    backgroundColor: '#fbeae8',
    borderRadius: '4px',
  },
  tablePaginationWrapper: { 
    display: "flex",
    alignItems: "center", 
    justifyContent: "center", 
    width: '100%',
    height: '64px',
    padding: '0px 10px',
    color: '#7d7a78',
    position: 'relative' as 'relative'
  },
  paginationInnerCommonWrapper: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between" 
  }
};
// Customizable Area End
